@charset "utf-8";

/*=================================
  汎用クラスの定義
=================================*/
.float-l {
  float: left;
}
.float-r {
  float: right;
}
.inner-wrap {
  max-width: $base-width;
  margin: 0 auto;
}
.is-sp-only {
  display: none!important;
}
.is-txt-center {
  text-align: center;
}
.notes {
  font-size: 1.4rem;
  display: block;
}
.cf:after {
  content:" ";
  display:block;
  clear:both;
}
.is-flex{
  display: flex;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
}
p {
  font-size: 1.6rem;
  line-height: 1.875;
}
/*共通レイアウト*/
.layoout--2col {
  display: flex;
  justify-content: space-between;
  &__left {
    width: calc(100% - 280px);
    padding: 0 10px;
  }
  .sideNav {
    width: 260px;
    margin-left: 20px;
  }
}
.widelink__cover {
  img {
     width: 100%;
  }
}
/*タイトル　バリエーション　サイズ別*/
  /*大見出し*/
  .ttl--lg {
    @include ttl-base(2.6rem,1.2);
    padding: 22px 0 18px 20px;
    background: linear-gradient(to right, #297dd2 0%,#3997e7 50%,#2989d8 100%);
    position: relative;
    &::before {
      content: "";
      width: 100%;
      height: 3px;
      position: absolute;
      top: 4px;
      left: 0;
      background: url(/images/border_section_ttl.png) repeat-x;
      display: block;
    }
  }
  /*大見出し 装飾付き*/
  .ttl--deco--lg {
    font-size: 3.6rem;
    font-weight: bold;
    line-height: 1.194444;
    padding: 34px 14px;
    position: relative;
    &::before {
      content: "";
      width: 100%;
      height: 13px;
      background: url(/images/ttl_deco_top.png) no-repeat left center;
      background-size: cover;
      display: inline-block;
      position: absolute;
      top: 0;
      left: 0;
    }
    &::after {
      content: "";
      width: 100%;
      height: 13px;
      background: url(/images/ttl_deco_bottom.png) no-repeat right center;
      background-size: cover;
      display: inline-block;
      position: absolute;
      bottom: 0;
      left: 0;
    }
    span {
      display: block;
    }
  }
  /*小見出し circle*/
  .ttl--circle--lg {
    @include ttl-base(2.4rem,22px,600,$font-color-base);
    background: url(/images/icon_circle.png) no-repeat left center;
    background-size: 22px 22px;
    padding: 0 0 0 32px;
  }
  /*小見出し check*/
  .ttl--check--blue {
    @include ttl-base(1.6rem,1,normal,#4e4e4e);
    &::before {
      content: "\f058";
      font-family: "FontAwesome";
      color: $font-color-link;
      margin-right: 5px;
      display: inline-block;
      vertical-align: top;
    }
  }
  .ttl--check--orange {
    @include ttl-base(1.6rem,1,normal,#4e4e4e);
    &::before {
      content: "\f058";
      font-family: "FontAwesome";
      color: #f58a0c;
      margin-right: 5px;
      display: inline-block;
      vertical-align: top;
    }
  }

  /*セクション title*/
  .sec__ttl {
    padding-left: 135px;
  }
  .secCampaign {
    .sec__ttl::after {
      content: "";
      width: 88px;
      height: 70px;
      background: url(/images/icon_section_ttl01.png) no-repeat center top;
      position: absolute;
      top: -8px;
      left: 22px;
    }
  }
  .secRanking {
    .sec__ttl::after {
      content: "";
      width: 76px;
      height: 82px;
      background: url(/images/icon_section_ttl02.png) no-repeat center top;
      position: absolute;
      top: calc(50% + 4px);
      left: 22px;
      transform: translateY(-50%);
    }
  }
  .secReport {
    .sec__ttl::after {
      content: "";
      width: 86px;
      height: 74px;
      background: url(/images/icon_section_ttl03.png) no-repeat center top;
      position: absolute;
      top: -7px;
      left: 22px;
    }
  }
  .secBasic {
    .sec__ttl::after {
      content: "";
      width: 86px;
      height: 74px;
      background: url(/images/icon_section_ttl04.png) no-repeat center top;
      position: absolute;
      top: -7px;
      left: 22px;
    }
  }

.listStyle--dots {
  li {
    font-size: 1.4rem;
    line-height: 1.71428571;
    &::before {
      content: "・";
      display: inline-block;
      vertical-align: middle;
      margin: 0 -2px 0 0;
    }
  }
}
.listStyle--arrow {
  li {
    font-size: 1.6rem;
    line-height: 1.5;
    &::before {
      content: "\f138";
      font-family: "FontAwesome";
      color: $font-color-link;
      margin-right: 5px;
      display: inline-block;
      vertical-align: top;
    }
  }
}
.pointNum {
  li {
    font-size: 1.6rem;
    line-height: 1.5;
    counter-increment: pointCount;
    > * {
      &::before {
        content: "";
        font-size: 1.4rem;
        line-height: 20px;
        color: #fff;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        vertical-align: middle;
        background: #5fa2d0;
        margin: 0 8px 0 0;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        content: counter(pointCount);
      }
    }
  }
}
/*link*/
.link--arrow--lg {
  font-size: 2rem;
  font-weight: 600;
  padding:  0 0 0 26px;
  position: relative;
  &::before {
    content: "\f138";
    font-family: "FontAwesome";
    line-height: 1;
    text-decoration: none;
    color: #2f76bd;
    position: absolute;
    top: 0.1em;
    left: 0;
  }
}
/*point box*/
.pointBox {
  background: #fffef2;
  border: 1px solid #ffeb8f;
  padding: 20px;
  li + li {
    margin: 10px 0 0;
  }
}
.pointBox {
  margin: 20px 0 0;
  padding: 0 0 17px;
  border: 2px solid #eff0b5;
  background: none;
  dt {
    font-size: 1.8rem;
    font-weight: 600;
    line-height: 1;
    color: #4e4e4e;
    background: #eff0b5;
    padding: 8px 0 8px 15px;
    margin: 0 0 18px;
  }
  dd {
    font-size: 1.8rem;
    font-weight: 600;
    line-height: 1.2;
    padding: 0 20px;
    &::before {
      content: "\f058";
      font-family: "FontAwesome";
      color: #f58a0c;
      display: inline-block;
      margin-right: 7px;
    }
    & + dd {
      margin-top: 20px;
    }
  }
}
/*shopList*/
.shopList {
  border: 3px solid #eff0b5;
  padding: 20px 20px 25px;
  dl {
    border-bottom: 1px dashed #acacac;
    padding-bottom: 20px;
    margin-bottom: 20px;
    display: flex;
  }
  dt {
    font-size: 1.8rem;
    font-weight: bold;
    line-height: 1;
    width: 80px;
    margin-right: 25px;
    &::before {
      content: "\f058";
      font-family: "FontAwesome";
      color: #367abf;
      margin-right: 7px;
    }
  }
  dd {
    font-size: 1.6rem;
    width: calc(100% - 105px);
    ul {
      display: flex;
      flex-wrap: wrap;
    }
    li {
      line-height: 1;
      width: 50%;
      &::before {
        content: "・";
      }
      &:nth-child(n + 3) {
        margin-top: 20px;
      }
    }
  }
}
/*reviews*/
.salonCard .reviews {
  border-top: 1px dashed #74ace3;
  margin-top: 36px;
  padding-top: 16px;
}
.reviews {
  &__head {
    display: flex;
    justify-content: space-between;
    margin: 0 0 40px;
  }
  &Ttl {
    font-size: 2rem;
    &::before {
      content: "";
      width: 35px;
      height: 29px;
      background: url(/images/icon_reviews.png) no-repeat center center;
      background-size: contain;
      display: inline-block;
      vertical-align: middle;
      margin-right: 17px;
    }
  }
  &Num {
    margin: 0!important;
    a {
      font-weight: bold;
    }
  }
  &Item {
    margin: 0 0 30px;
    &__list {
      display: flex;
      .figure {
        width: 98px;
        margin-right: 22px;
        img {
          margin: 0;
        }
        figcaption {
          font-size: 1.3rem;
          line-height: 1.25;
          text-align: center;
          margin-top: 5px;
          span {
            display: block;
          }
        }
      }
      &:nth-child(2n) {
        flex-direction: row-reverse;
        .figure {
          margin-right: 0;
          margin-left: 22px;
        }
      }
      & + .reviewsItem__list {
        margin-top: 55px;
      }
    }
  }
  &Cont {
    width: calc(100% - 120px);
    &__head {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      padding-bottom: 10px;
      margin-bottom: 10px;
      border-bottom: 1px dashed #a9aa68;
      p {
        font-size: 1.8rem;
        font-weight: bold;
        line-height: 1;
        color: #019992;
        margin: 0!important;
      }
      .rating {
        display: flex;
        align-items: center;
        span {
          font-size: 1.9rem;
          font-weight: bold;
          line-height: 1;
          color: #a80000;
          margin-right: 10px;
        }
      }
      .starArea {
        display: flex;
        li {
          line-height: 1;
        }
        img {
          width: 19px;
          margin: 0;
        }
      }
    }
    .text {
      font-size: 1.3rem;
      line-height: 1.5384;
    }
  }
  .link--arrow--lg {
    margin: 0 auto 0 0;
  }
}
/*report*/
.reportForm {
  background: url(/images/bg_report.png) repeat left top;
  padding: 20px;
  margin-bottom: 48px;
  position: relative;
  &::before {
    content: "";
    display: inline-block;
    position: absolute;
    top: 5px;
    left: 5px;
    width: 56px;
    height: 42px;
    background: no-repeat center center;
    background-image: url(/images/deco_tape.png);
    background-size: contain;
    z-index: 1;
  }
  &::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 5px;
    right: 5px;
    transform: scale(-1, 1);
    width: 56px;
    height: 42px;
    background: no-repeat center center;
    background-image: url(/images/deco_tape.png);
    background-size: contain;
    z-index: 1;
  }
  &__inner {
    background: #fff;
    padding: 20px 20px 35px;
    border-bottom-left-radius: 30px;
    position: relative;
    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 30px;
      height: 30px;
      display: inline-block;
      background: no-repeat center center;
      background-image: url(/images/deco_leftend.png);
      background-size: contain;
      z-index: 1;
    }
  }
  &Ttl {
    font-size: 2.4rem!important;
    color: $font-color-base!important;
    background: none!important;
    border-bottom: 1px dashed #7ebbef;
    margin: 0!important;
    padding: 0 0 12px!important;
    &::before {
      content: none!important;
    }
  }
  &Text {
    font-size: 1.4rem;
    margin: 18px 0 25px!important;
    span {
      font-size: 1.3rem;
      color: #d31a1a;
      display: block;
    }
  }
  &__item--2col {
    display: flex;
    justify-content: space-between;
    > div {
      width: calc(100% - 20px);
      & + div {
        margin-left: 40px;
      }
    }
  }
  dl {
    border-top: 1px dashed #c1c1c1;
    padding-top: 10px;
    margin-bottom: 30px;
    dt {
      font-size: 1.6rem;
      font-weight: bold;
      margin-bottom: 5px;
    }
  }
  input {
    border: 1px solid #ccc;
    padding: 5px;
    &[type="text"] {
      box-shadow: inset 1px 1px 1px rgba(0,0,0,0.1);
      width: 100%;
    }
    &[type="radio"] {
      margin-right: 5px;
    }
    &:nth-child(n + 2) {
      margin-left: 30px;
    }
  }
  textarea {
    width: 100%;
    min-height: 130px;
    border: 1px solid #ccc;
    box-shadow: inset 1px 1px 1px rgba(0,0,0,0.1);
    padding: 5px;
  }
}
/*toc box*/
.tocBox {
  background: #fcfaf4;
  padding: 15px 0 0;
  margin: 70px 0 50px;
  ul {
    background: none!important;
    border: none!important;
    padding: 0!important;
    margin: 0!important;
  }
  li {
    font-size: 1.8rem!important;
    padding: 0!important;
    line-height: 1.5;
    counter-increment: tocCount;
    &::before {
      content: none!important;
    }
    > * {
      padding: 15px 18px;
      border-top: 1px dashed #dadada;
      display: block;
      &::before {
        content: "";
        font-size: 1.6rem;
        line-height: 25px;
        color: #fff;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        vertical-align: middle;
        background: #27a8b6;
        margin: 0 15px 0 0;
        width: 25px;
        height: 25px;
        border-radius: 50%;
        content: counter(tocCount);
      }
    }
  }
  .pickUp {
    background: #f9f4e7;
    margin: 0;
    .icon--pickUp {
      font-size: 1.3rem;
      line-height: 1;
      color: #fff;
      background: #ee427e;
      padding: 6px 9px;
      text-decoration: none;
      display: inline-block;
      margin: 0 10px 0 0;
      border-radius: 2px;
    }
    a {
      display: block;
      padding: 18px 15px;
    }
  }
  .tocTtl {
    text-align: center;
    position: relative;
    top: -10px;
    z-index: 10;
    p {
      font-size: 2.6rem;
      font-weight: 600;
      line-height: 1;
      color: #fff;
      background: #46b5c2;
      box-shadow: 0 30px 0 -15px #fcfaf4;
      display: inline-block;
      padding: 10px 93px;
      margin: 0;
      position: relative;
      top: -40px;
      right: 0;
      left: 0;
      &::before {
        content: "";
        width: calc(100% + 75px);
        display: block;
        position: absolute;
        top: 15px;
        left: 50%;
        transform: translateX(-50%);
        border-top: 20px solid #79ccd6;
        border-right: 17px solid transparent;
        border-bottom: 25px solid #79ccd6;
        border-left: 17px solid transparent;
        box-sizing: border-box;
        z-index: -1;
      }
      &::after {
        content: "";
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        margin-bottom: -30px;
        border: 15px solid transparent;
        border-top-color: #b5e0e6;
        box-sizing: border-box;
        z-index: -1;
      }
    }
  }
}
/*roundup box*/
.roundupBox {
  background: #fcfaf4;
  padding: 15px 20px 20px;
  margin: 75px 0 0;
  li {
    font-size: 1.8rem;
    font-weight: 600;
    line-height: 1.5;
    padding: 15px 15px 15px 50px;
    border-top: 1px dashed #dadada;
    position: relative;
    &::before {
      content: "";
      width: 36px;
      height: 36px;
      background: url(/images/icon_check_jagged_red.png) no-repeat left center;
      background-size: contain;
      display: block;
      position: absolute;
      top: 50%;
      left: 5px;
      transform: translateY(-50%);
    }
  }
  .roundupTtl {
    text-align: center;
    position: relative;
    top: -10px;
    z-index: 10;
    p {
      font-size: 2.6rem;
      font-weight: 600;
      line-height: 1;
      color: #fff;
      background: #d82f8c;
      box-shadow: 0 30px 0 -15px #fcfaf4;
      display: inline-block;
      padding: 10px 80px;
      margin: 0;
      position: relative;
      top: -40px;
      right: 0;
      left: 0;
      &::before {
        content: "";
        width: calc(100% + 75px);
        display: block;
        position: absolute;
        top: 15px;
        left: 50%;
        transform: translateX(-50%);
        border-top: 20px solid #e668ad;
        border-right: 17px solid transparent;
        border-bottom: 25px solid #e668ad;
        border-left: 17px solid transparent;
        box-sizing: border-box;
        z-index: -1;
      }
      &::after {
        content: "";
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        margin-bottom: -30px;
        border: 15px solid transparent;
        border-top-color: #efadd1;
        box-sizing: border-box;
        z-index: -1;
      }
    }
  }
}
/*list arrow*/
.list--arrow {
  border: none!important;
  border-bottom: 1px dashed #acacac!important;
  padding: 0!important;
  margin: 0!important;
  background: none!important;
  &__item {
    padding: 27px 0 27px 17px!important;
    border-top: 1px dashed #acacac;
    display: flex;
    align-items: flex-start;
    position: relative;
    .thumb {
      width: 100px;
      height: auto;
      margin: 0 15px 0 0;
      img {
        width: 100%;
        margin: 0!important;
      }
    }
    &::before {
      content: "\f105"!important;
      font-family: "FontAwesome"!important;
      font-size: 2.3rem!important;
      line-height: 100px!important;
      color: #fff!important;
      text-align: center!important;
      width: 14px!important;
      height: 100px;
      display: inline-block!important;
      position: absolute!important;
      top: 27px!important;
      left: 0!important;
    }
    .summary {
      width: calc(100% - 115px);
      a {
        font-size: 1.8rem;
        font-weight: bold;
      }
      p {
        font-size: 1.3rem;
        line-height: 1.53846;
        margin-top: 10px;
      }
    }
    & + .list--arrow__item {
      margin: 0;
    }
  }
  &.green .list--arrow__item::before {
    background: #1eb7b0;
  }
  &.blue .list--arrow__item::before {
    background: #3b85d0;
  }
  &.purple .list--arrow__item::before {
    background: #c449b1;
  }
}
/*breadcrumb*/
.breadcrumb {
  ul {
    display: flex;
    align-items: center;
    margin-bottom: 28px;
    padding: 0 10px;
    li {
      font-size: 1.4rem;
      & + li {
        &::before {
          content: "\f061";
          font-family: "FontAwesome";
          color: #cbcbcb;
          margin: 0 16px;
        }
      }
    }
  }
}
/*pagenation*/
.pagenation {
  margin: 15px 0 0;
  .listCount {
    font-size: 1.4rem;
    text-align: right;
    span {
      font-size: 2.1rem;
      font-weight: bold;
    }
  }
  .pager {
    display: flex;
    justify-content: center;
    margin: 35px 0 40px;
    li {
      * {
        font-size: 1.8rem;
        line-height: 1;
        color: #fff;
        background: #2a7fd4;
        border: 1px solid #2a7fd4;
        text-decoration: none;
        display: block;
        border-radius: 3px;
        padding: 5px 9px;
      }
      a:hover {
        background: #fff;
        color: #2a7fd4;
      }
      span {
        color: #2a7fd4;
        background: #fff;
      }
      & + li {
        margin-left: 10px;
      }
    }
  }
}
/*ボタン*/
.btnWrap {
  text-align: center;
  margin: 40px 0 0;
  p {
    margin: 0!important;
  }
}
.btn--pink {
  text-align: center;
  margin: 23px 0 60px;
  a {
    font-size: 2rem;
    font-weight: bold;
    line-height: 1;
    color: #fff;
    text-decoration: none;
    padding: 18px 70px;
    margin: 0 0 20px;
    display: inline-block;
    border-radius: 5px;
    background: #ec4e74;
    background: -webkit-linear-gradient(top, #ec4e74 0%, #f46395 1%, #eb3473 100%);
    background: -webkit-gradient(linear, left top, left bottom, from(#ec4e74), color-stop(1%, #f46395), to(#eb3473));
    background: linear-gradient(to bottom, #ec4e74 0%, #f46395 1%, #eb3473 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ec4e74', endColorstr='#eb3473',GradientType=0 );
    -webkit-box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.3), inset 0px 5px 5px 0px #ef407b;
    box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.3), inset 0px 5px 5px 0px #ef407b;
    border-top: 2px solid #f9c7d3;
    border-left: 2px solid #f38da4;
    border-bottom: 1px solid #ef1941;
    border-right: 2px solid #f63960;
    &:hover {
      background: #eb3473;
      background: -webkit-linear-gradient(top, #eb3473 0%, #f46395 99%, #ec4e74 100%);
      background: -webkit-gradient(linear, left top, left bottom, from(#eb3473), color-stop(99%, #f46395), to(#ec4e74));
      background: linear-gradient(to bottom, #eb3473 0%, #f46395 99%, #ec4e74 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#eb3473', endColorstr='#ec4e74',GradientType=0 );
      color: #fff;
    }
  }
}
.btn--blue {
  font-size: 2rem;
  font-weight: bold;
  line-height: 1;
  color: #fff;
  text-decoration: none;
  padding: 18px 25px!important;
  margin: 0 0 20px;
  display: inline-block;
  border-radius: 5px;
  background: #6fa2d9;
  background: -webkit-linear-gradient(top, #6fa2d9 0%, #7baedd 1%, #468bcd 100%);
  background: -webkit-gradient(linear, left top, left bottom, from(#6fa2d9), color-stop(1%, #7baedd), to(#468bcd));
  background: linear-gradient(to bottom, #6fa2d9 0%, #7baedd 1%, #468bcd 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#6fa2d9', endColorstr='#468bcd',GradientType=0 );
  -webkit-box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.3), inset 0px 5px 5px 0px #468bcd;
  box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.3), inset 0px 5px 5px 0px #468bcd;
  border-top: 2px solid #d6e6f6!important;
  border-left: 2px solid #abccec!important;
  border-bottom: 1px solid #4c6589!important;
  border-right: 2px solid #8291af!important;
  &:hover {
    background: #468bcd;
    background: -webkit-linear-gradient(top, #468bcd 0%, #7baedd 99%, #6fa2d9 100%);
    background: -webkit-gradient(linear, left top, left bottom, from(#468bcd), color-stop(99%, #7baedd), to(#6fa2d9));
    background: linear-gradient(to bottom, #468bcd 0%, #7baedd 99%, #6fa2d9 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#468bcd', endColorstr='#ec4e74',GradientType=0 );
    color: #fff;
  }
}
.btn--green {
  font-size: 2.8rem;
  font-weight: bold;
  line-height: 1;
  color: #fff;
  text-decoration: none;
  padding: 18px 70px;
  margin: 0 0 20px;
  display: inline-block;
  border-radius: 5px;
  background: #30b336;
  background: -webkit-linear-gradient(top, #30b336 0%, #30c930 1%, #31b438 100%);
  background: -webkit-gradient(linear, left top, left bottom, from(#30b336), color-stop(1%, #30c930), to(#31b438));
  background: linear-gradient(to bottom, #30b336 0%, #30c930 1%, #31b438 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#30b336', endColorstr='#31b438',GradientType=0 );
  -webkit-box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.3), inset 0px 5px 5px 0px #30af41;
  box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.3), inset 0px 5px 5px 0px #30af41;
  border-top: 2px solid #bfe6cf;
  border-left: 2px solid #7dcd92;
  border-bottom: 1px solid #017623;
  border-right: 2px solid #009e43;
  &:hover {
    background: #31b438;
    background: -webkit-linear-gradient(top, #31b438 0%, #30c930 99%, #30b336 100%);
    background: -webkit-gradient(linear, left top, left bottom, from(#31b438), color-stop(99%, #30c930), to(#30b336));
    background: linear-gradient(to bottom, #31b438 0%, #30c930 99%, #30b336 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#31b438', endColorstr='#ec4e74',GradientType=0 );
    color: #fff;
  }
}

@media screen and (max-width: $display-width-s){
  /* =================================
    base
  ================================= */
  html,body {
    min-width: 320px;
  }
  /* =================================
    汎用クラスの定義
  ================================= */
  .inner-wrap {
    width: 100%;
  }
  .is-sp-only {
    display: block!important;
  }
  .is-pc-only {
    display: none!important;
  }
  .float-l,.float-r {
    float: none;
  } 
  .is-flex{
    display: block;
  }
  p {
    font-size: 1.4rem;
    line-height: 1.7857;
  }
  /*共通レイアウト*/
  .layoout--2col {
    display: block;
    &__left {
      width: 100%;
    }
    .sideNav {
      width: 100%;
      margin-left: 0;
    }
  }
  /*タイトル　バリエーション　サイズ別*/
    /*大見出し*/
    .ttl--lg {
      padding: 16px 15px 13px;
      &::before {
        height: 2px;
        top: 2px;
      }
    }
    /*大見出し 装飾付き*/
    .ttl--deco--lg {
      font-size: 1.8rem;
      line-height: 1.222222;
      padding: 18px 6px;
      margin-bottom: 15px;
      &::before {
        height: 7px;
      }
      &::after {
        height: 7px;
      }
      span {
        display: block;
      }
    }
    /*小見出し circle*/
    .ttl--circle--lg {
      background: url(/images/icon_circle.png) no-repeat left 3px;
      background-size: 13px 13px;
      padding: 0 0 0 22px;
    }
    /*小見出し check*/
    .ttl--check--blue {
      font-size: 1.4rem;
    }
    .ttl--check--orange {
      font-size: 1.4rem;
    }
  /*セクション title*/
  .sec__ttl {
    padding-left: 64px;
  }
  .secCampaign {
    .sec__ttl::after {
      width: 46px;
      height: 41px;
      background-size: contain;
      top: 50%;
      left: 10px;
      transform: translateY(-50%);
    }
  }
  .secRanking {
    .sec__ttl::after {
      width: 39px;
      height: 45px;
      background-size: contain;
      top: 50%;
      left: 10px;
      transform: translateY(-50%);
    }
  }
  .secReport {
    .sec__ttl::after {
      width: 47px;
      height: 35px;
      background-size: contain;
      top: 50%;
      left: 10px;
      transform: translateY(-50%);
    }
  }
  .secBasic {
    .sec__ttl::after {
      width: 44px;
      height: 42px;
      background-size: contain;
      top: 50%;
      left: 10px;
      transform: translateY(-50%);
    }
  }
  /*link*/
  .link--arrow--lg {
    font-size: 1.6rem;
    padding:  0 0 0 20px;
    display: block;
    text-align: left;
  }
  /*point box*/
  .pointBox {
    padding: 0 0 12px;
    border: 1px solid #eff0b5;
    dt {
      font-size: 1.5rem;
      padding: 10px 0 10px 12px;
      margin: 0 0 13px;
    }
    dd {
      font-size: 1.4rem;
      padding: 0 13px;
      & + dd {
        margin-top: 15px;
      }
    }
  }
  /*shopList*/
  .shopList {
    border: 2px solid #eff0b5;
    padding: 12px 10px;
    dl {
      padding-bottom: 15px;
      margin-bottom: 10px;
      display: block;
    }
    dt {
      font-size: 1.6rem;
      width: auto;
      margin: 0 0 10px;
    }
    dd {
      font-size: 1.2rem;
      width: 100%;
      li {
        width: 48%;
        &:nth-child(n + 3) {
          margin-top: 10px;
        }
        &:nth-child(2n) {
          margin-left: 2%;
        }
      }
    }
  }
  /*reviews*/
  .salonCard .reviews {
    margin-top: 30px;
    padding-top: 10px;
  }
  .reviews {
    &__head {
      display: block;
      margin: 0 0 28px;
    }
    &Ttl {
      font-size: 1.6rem;
      &::before {
        width: 22px;
        height: 18px;
        margin-right: 9px;
      }
    }
    &Num {
      margin: 8px 0 0!important;
    }
    &Item {
      &__list {
        .figure {
          width: 60px;
          margin-right: 12px;
          figcaption {
            font-size: 1rem;
          }
        }
        &:nth-child(2n) {
          flex-direction: row;
          .figure {
            margin-right: 12px;
            margin-left: 0;
          }
        }
        & + .reviewsItem__list {
          margin-top: 35px;
        }
      }
    }
    &Cont {
      width: calc(100% - 72px);
      &__head {
        display: block;
        padding-bottom: 0;
        margin-bottom: 0;
        border-bottom: none;
        p {
          font-size: 1.6rem;
          padding: 0 0 7px;
          margin: 0 0 6px!important;
          border-bottom: 1px dashed #a9aa68;
        }
        .rating {
          margin: 0 0 10px;
          span {
            font-size: 1.5rem;
          }
        }
      }
    }
    .link--arrow--lg {
      margin: 0;
    }
  }
  /*report*/
  .reportForm {
    padding: 10px;
    margin-bottom: 25px;
    &::before {
      width: 28px;
      height: 21px;
    }
    &::after {
      width: 28px;
      height: 21px;
    }
    &__inner {
      padding: 13px 14px 16px;
      border-bottom-left-radius: 15px;
      &::after {
        width: 15px;
        height: 15px;
      }
    }
    &Ttl {
      font-size: 1.6rem!important;
      padding: 0 0 8px!important;
    }
    &Text {
      margin: 8px 0 15px!important;
      span {
        font-size: 1.2rem;
      }
    }
    &__item--2col {
      display: block;
      > div {
        width: 100%;
        & + div {
          margin-left: 0;
        }
      }
    }
    dl {
      margin-bottom: 15px;
      dt {
        font-size: 1.4rem;
      }
    }
    input {
      box-shadow: none;
      font-size: 1.6rem;
      &[type="radio"] {
        margin-right: 2px;
      }
      &:nth-child(n + 2) {
        margin-left: 5px;
      }
    }
    textarea {
      font-size: 1.6rem;
      border-radius: 0;
      box-shadow: none;
    }
  }
  /*toc box*/
  .tocBox {
    padding: 0;
    margin: 50px 0 30px;
    li {
      font-size: 1.4rem!important;
      > * {
        padding: 12px 10px;
        &::before {
          font-size: 1.3rem;
          line-height: 20px;
          margin: 0 8px 0 0;
          width: 20px;
          height: 20px;
          content: counter(tocCount);
        }
      }
    }
    .pickUp {
      .icon--pickUp {
        font-size: 1.3rem;
        padding: 5px 6px 3px;
        margin: 0 6px 0 0;
      }
      a {
        display: block;
        padding: 12px 10px;
      }
    }
    .tocTtl {
      top: 20px;
      p {
        font-size: 1.3rem;
        box-shadow: 0 16px 0 -8px #fcfaf4;
        padding: 5px 45px;
        top: -39px;
        &::before {
          width: calc(100% + 20px);
          border-top: 12px solid #79ccd6;
          border-right: 7px solid transparent;
          border-bottom: 12px solid #79ccd6;
          border-left: 7px solid transparent;
          top: 7px;
        }
        &::after {
          margin-bottom: -18px;
          border: 10px solid transparent;
          border-top-color: #b5e0e6;
          box-sizing: border-box;
          z-index: -1;
        }
      }
    }
  }
  /*roundup box*/
  .roundupBox {
    padding: 0;
    margin: 50px 0 30px;
    li {
      font-size: 1.4rem;
      line-height: 1.57142;
      padding: 11px 11px 11px 45px;
      &::before {
        width: 25px;
        height: 25px;
        left: 11px;
      }
    }
    .roundupTtl {
      top: 20px;
      p {
        font-size: 1.3rem;
        box-shadow: 0 16px 0 -8px #fcfaf4;
        padding: 5px 45px;
        top: -39px;
        &::before {
          width: calc(100% + 20px);
          border-top: 12px solid #e668ad;
          border-right: 7px solid transparent;
          border-bottom: 12px solid #e668ad;
          border-left: 7px solid transparent;
          top: 7px;
        }
        &::after {
          margin-bottom: -18px;
          border: 10px solid transparent;
          border-top-color: #efadd1;
          box-sizing: border-box;
          z-index: -1;
        }
      }
    }
  }
  /*list arrow*/
  .list--arrow {
    &__item {
      padding: 9px 0 9px 9px!important;
      .thumb {
        width: 50px;
        margin: 0 9px 0 0;
      }
      &::before {
        font-size: 1.4rem!important;
        line-height: 50px!important;
        width: 7px!important;
        height: 50px;
        top: 9px!important;
      }
      .summary {
        width: calc(100% - 59px);
        a {
          font-size: 1.3rem;
        }
        p {
          display: none;
        }
      }
      & + .list--arrow__item {
        margin: 0;
      }
    }
  }
  /*breadcrumb*/
  .breadcrumb {
    overflow-x: auto;
    white-space: nowrap;
    ul {
      margin-bottom: 10px;
      padding: 15px 10px 0;
      li {
        font-size: 1.2rem;
        & + li {
          &::before {
            margin: 0 8px;
          }
        }
      }
    }
  }
  /*pagenation*/
  .pagenation {
    margin: 10px 0;
    .listCount {
      font-size: 1.2rem;
      span {
        font-size: 1.7rem;
      }
    }
    .pager {
      margin: 15px 0;
      li {
        * {
          font-size: 1.7rem;
          padding: 3px 7px;
        }
        & + li {
          margin-left: 5px;
        }
      }
    }
  }
  /*ボタン*/
  .btnWrap {
    margin: 20px 0;
  }
  .btn--pink {
    margin: 15px 0 30px;
    a {
      font-size: 1.5rem;
      padding: 18px 0;
      margin: 0 0 10px;
      display: block;
    }
  }
  .btn--blue {
    font-size: 1.4rem;
    padding: 15px 5px;
    margin: 0 0 10px;
    display: block;
    width: 100%;
  }
  .btn--green {
    font-size: 1.7rem;
    padding: 15px 5px;
    margin: 0 0 10px;
    display: block;
  }
}
